<template>
  <div style="border: none">
    <!-- deploy -->
    <div v-if="settingValue === 2">
      <div v-if="display == 3">
        Estimated Customers : {{ estimatedCustomer.userSend | numeral("0,0") }}

        {{
          form.is_not_consent_send == 0 || form.is_not_consent_send == 2
            ? `(Total
        ${estimatedCustomer.userAll.toLocaleString()} - ${
                isNaN(estimatedCustomer.userPercen)
                  ? 0
                  : (estimatedCustomer.userPercen * 100).toFixed(2)
              }%)`
            : "(All)"
        }}
      </div>
      <!-- 
      <template v-if="display == 5">
        <div>Condition : Unset attribute</div>
      </template> -->
      <template v-else>
        <div>Condition : Unconditional</div>
      </template>
    </div>
    <div v-else>
      <div v-if="display === 4">
        <div v-for="(action, i) in this.form.action" :key="i">
          <span v-show="false">{{ action }}</span>
          <b-row v-if="action.action_type_id === 1">
            <b-col md="6" lg="4" xl="3">{{ action.action_type_name }} :</b-col>
            <b-col cols="8" xl="9" class="font-w-600">{{
              valueOfRedeem(action.action_value)
            }}</b-col>
          </b-row>
          <b-row
            v-if="action.action_type_id === 2 || action.action_type_id == 6"
          >
            <b-col md="6" lg="4" xl="3"
              >{{ action.action_type_name }}
              <template v-if="action.action_type_id != 6"> : </template></b-col
            >
            <b-col
              cols="8"
              xl="9"
              class="font-w-600"
              v-if="action.action_type_id != 6"
              >{{ action.action_value }} Point</b-col
            >
          </b-row>
        </div>
      </div>
      <div v-else-if="display === 2">
        <div v-for="(action, i) in this.form.list_action" :key="i">
          <span v-show="false">{{ action }}</span>
          <b-row v-if="action.action_type_id === 1">
            <b-col md="6" lg="4" xl="3">{{ action.action_type_name }} :</b-col>
            <b-col cols="8" xl="9" class="font-w-600">{{
              valueOfRedeem(action.action_value)
            }}</b-col>
          </b-row>
          <b-row
            v-if="action.action_type_id === 2 || action.action_type_id == 6"
          >
            <b-col md="6" lg="4" xl="3"
              >{{ action.action_type_name }}
              <template v-if="action.action_type_id != 6"> : </template></b-col
            >
            <b-col
              cols="8"
              xl="9"
              class="font-w-600"
              v-if="action.action_type_id != 6"
              >{{ action.action_value }} Point</b-col
            >
          </b-row>
          <b-row
            v-if="action.action_type_id === 3 || action.action_type_id === 4"
          >
            <b-col md="6" lg="4" xl="3">{{ action.action_type_name }} :</b-col>
            <b-col cols="8" xl="9" class="font-w-600"
              >{{ valueOfMission(action.action_value) }}
            </b-col>
          </b-row>
        </div>
      </div>

      <template v-else-if="display == 1 || display == 3">
        <template v-if="display == 3">
          Estimated Customers :
          {{ estimatedCustomer.userSend | numeral("0,0") }}
          {{
            form.is_not_consent_send == 0 || form.is_not_consent_send == 2
              ? `(Total
        ${estimatedCustomer.userAll.toLocaleString()} - ${
                  isNaN(estimatedCustomer.userPercen)
                    ? 0
                    : (estimatedCustomer.userPercen * 100).toFixed(2)
                }%)`
              : "(All)"
          }}
          <font-awesome-icon
            icon="download"
            class="pointer mx-2 text-custom-primary"
            @click="openModal"
          />
        </template>
        <div v-for="(group, i) of form.condition_group" :key="'group' + i">
          <!-- <hr v-if="i != 0" /> -->
          <b> Group : {{ i + 1 }}</b>
          <div
            v-for="(item, index) in group.condition"
            :key="index"
            :set="(optionType = getTypeOfOptions(item.condition_type_id))"
            class="ml-3"
          >
            <b-row>
              <b-col md="6" lg="4" xl="3"
                >{{ item.condition_type_name }} :
              </b-col>
              <b-col md="6" lg="8" xl="9" class="font-w-600">
                <span
                  class="mr-3"
                  v-if="
                    optionType == 'int' ||
                    optionType == 'Datetime' ||
                    optionType == 'Birthday'
                  "
                >
                  <template
                    v-if="optionType == 'Datetime' || optionType == 'Birthday'"
                  >
                    {{ convertOperation(item.operation_type_id) }}
                  </template>
                  <template v-else>{{ item.operation_type_name }} </template>
                </span>
                <span v-if="optionType == 'Datetime'">
                  <span v-if="item.date_type == 3">
                    {{ $moment(item.condition_value).format("DD/MM/YYYY ") }}
                  </span>
                  <span v-else-if="item.date_type == 1">
                    {{ $moment().format("DD/MM/YYYY ") }}
                  </span>
                  <span v-else-if="item.date_type == 2">This Month</span>
                  <span v-else>
                    {{
                      $moment(item.condition_value).format("DD/MM/YYYY ")
                    }}</span
                  >
                </span>
                <span v-else-if="optionType == 'Birthday'">
                  <span v-if="item.date_type == 3">
                    {{
                      typeof item.condition_value == "object"
                        ? item.condition_value
                            .map((month) =>
                              $moment()
                                .months(month - 1)
                                .format("MMMM")
                            )
                            .join(", ")
                        : $moment()
                            .months(item.condition_value - 1)
                            .format("MMMM")
                    }}
                  </span>

                  <span v-else-if="item.date_type == 2">This Month</span>
                </span>
                <span v-else-if="optionType == 'Dropdown'">
                  <template v-if="item.condition_type_id == 36">
                    <template v-if="item.operation_type_id == 3">
                      Include ({{
                        getNameOfValue(
                          item.condition_value,
                          getObjFromId(item.condition_type_id)
                        )
                      }})
                    </template>
                    <template v-if="item.operation_type_id == 7">
                      Exclude ({{
                        getNameOfValue(
                          item.condition_value,
                          getObjFromId(item.condition_type_id)
                        )
                      }})
                    </template>
                  </template>
                  <template v-else>
                    {{
                      getNameOfValue(
                        item.condition_value,
                        getObjFromId(item.condition_type_id)
                      )
                    }}
                  </template>
                </span>
                <span v-else class="d-inline-flex align-items-center">
                  {{ item.condition_value }}

                  <b-spinner
                    small
                    label="Spinning"
                    class="ml-2"
                    v-if="isFileLoading"
                  ></b-spinner>
                </span>
                <span v-if="item.operation_type_id == 6">
                  -
                  <span
                    v-if="optionType == 'Datetime' || optionType == 'Birthday'"
                    >{{ $moment(item.between_value).format("DD/MM/YYYY ") }}
                  </span>
                  <span v-else>{{ item.between_value }}</span></span
                >
                <span
                  v-if="
                    item.operation != 0 &&
                    item.operation_value != 0 &&
                    (optionType == 'Datetime' || optionType == 'Birthday')
                  "
                >
                  ( {{ item.operation == 1 ? "+" : "-"
                  }}{{ item.operation_value }}
                  {{ item.date_type == 2 ? "Month" : "Day" }})
                </span>
              </b-col>
              <b-col lg="4">
                <div class="my-2" v-if="group.condition.length - 1 != index">
                  <b>
                    {{
                      item.group_operation_type
                        ? item.group_operation_type.toUpperCase()
                        : "AND"
                    }}</b
                  >
                </div>
              </b-col>
              <div class="break-normal"></div>
            </b-row>
          </div>
          <div class="my-2" v-if="group.operation_type">
            <b> {{ group.operation_type.toUpperCase() }}</b>
          </div>
        </div>
      </template>
      <template v-else-if="display == 5">
        <div v-for="(action, i) in this.form.list_attribute" :key="i">
          <b-row v-if="action.automation_attribute_type_id === 1">
            <b-col md="6" lg="4" xl="3">Set Member Tier :</b-col>
            <b-col cols="8" xl="9" class="font-w-600"
              >{{ getNameOfValue(action.value, getObjFromId(1)) }}
            </b-col>
          </b-row>
        </div>
      </template>
    </div>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="formExport"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (formExport.email = val)"
      @submit="exportCustomer"
    />
  </div>
</template>

<script>
import ModalInputEmail from "@/components/customer/ModalInputEmail";
export default {
  name: "DisplayResultSendMessageGroup",
  components: {
    ModalInputEmail,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    display: {
      required: true,
      type: Number,
    },
    settingValue: {
      required: true,
      type: Number,
    },
    privilegeList: {
      required: false,
      // type: Array,
    },
    serviceList: {
      required: false,
    },
    branchList: {
      required: false,
    },
    productList: {
      required: false,
    },
    campaignList: {
      required: false,
    },
    stampList: {
      required: false,
      // type: Array,
    },
    gender: {
      required: false,
      // type: Array,
    },

    memberLevel: {
      required: false,
      type: Array,
    },
    redeemCodeList: {
      required: false,
    },
    conditionList: {
      required: false,
    },
    campaignFieldConditions: {
      required: false,
    },
    estimatedCustomer: {
      required: false,
    },
    isFileLoading: {
      required: false,
      default: false,
    },
    missionList: {
      required: false,
      default: () => [],
    },
    customerTag: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      formExport: {
        email: "",
        condition_group: [],
      },
      id: this.$route.params.id,
      dateOperatorList: [
        {
          id: 1,
          name: "After",
        },
        {
          id: 2,
          name: "Before",
        },
        {
          id: 3,
          name: "IS",
        },
        {
          id: 4,
          name: "After or Equal",
        },
        {
          id: 5,
          name: "Before or Equal",
        },
        {
          id: 6,
          name: "Between",
        },
      ],
    };
  },
  methods: {
    convertOperation(id) {
      return this.dateOperatorList.find((el) => el.id == id).name;
    },
    getTypeOfOptions(id) {
      if (id)
        return this.conditionList.find((el) => el.id == id)
          ? this.conditionList.find((el) => el.id == id).type
          : "";
    },
    getObjFromId(id) {
      if (id == 1) return this.memberLevel;
      if (id == 2) return this.gender;
      else if (id == 7) return this.privilegeList;
      else if (id == 11) return this.stampList;
      else if (id == 16 || id == 23 || id == 25) {
        return this.branchList;
      } else if (id == 17) return this.productList;
      else if (id == 18) return this.campaignList;
      else if (id == 27) return this.serviceList;
      else if (id == 36) return this.customerTag;
      else if (id == 37) return this.productList;
      else if (id == 38) return this.branchList;
      else if (id == 34)
        return [
          { id: "1", name: "Yes" },
          { id: "0", name: "No" },
        ];
    },
    getNameOfValue(val, obj) {
      try {
        if (typeof val == "object") {
          return val
            .map((els) => obj.find((el) => el.id == els)?.name)
            .join(" , ");
        } else {
          let value = obj.find((el) => el.id == val);

          return value?.name;
        }
      } catch (error) {
        console.log(error, "get name value");
      }
    },
    valueOfRedeem(val) {
      let value = this.redeemCodeList.find((el) => el.id == val);
      return value ? value?.name : val;
    },
    valueOfMission(val) {
      let value = this.missionList.find((el) => el.id == val);
      return value ? value?.name : val;
    },

    getFieldCampaign(val) {
      let choice = this.campaignFieldConditions.find((n) =>
        n.field_choices.find((i) => i.id == val)
      );
      if (choice) return choice.name;
      return "";
    },
    getFieldCampaignChoice(val) {
      let choice = this.campaignFieldConditions.find((n) =>
        n.field_choices.find((i) => i.id == val)
      );
      if (choice) return choice.field_choices.find((el) => el.id == val).name;
      return "";
    },

    valueOfStampCard(val) {
      let value = this.stampList.find((el) => el.id == val);

      return value ? value?.name : val;
    },
    openModal() {
      this.$refs.ModalInputEmail.show();
    },
    async exportCustomer() {
      let object = JSON.parse(JSON.stringify(this.form.condition_group));
      for (const group of object) {
        var tempObj = [];

        for (const list of group.condition) {
          list.operation_value = list.operation_value
            ? list.operation_value
            : 0;
          list.date_type = list.date_type ? list.date_type : 0;
          if (typeof list.condition_value == "object") {
            for (const value of list.condition_value) {
              tempObj.push({ ...list, condition_value: value });
            }
          } else {
            tempObj.push(list);
          }
        }

        group.condition = tempObj;
      }

      this.$bus.$emit("showLoading");
      this.formExport.condition_group = object;
      const resp = await this.axios.post(
        `/MarketingAutomation/export_customer_condition_list`,
        this.formExport
      );

      this.successAlert(
        "The file has been sent to email. (If not received, please wait 5-10 minutes.)"
      );
      this.$bus.$emit("hideLoading");
    },
    clearExportFilter() {
      this.formExport.email = "";
      this.formExport.user_guid_list = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.font-w-600 {
  font-weight: 600;
}
</style>
